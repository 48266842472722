import React from 'react'
import styles from './contactButton.module.css'

const ContactButton = ({darkMode}) => {
 const word = "let's create culture together";

return(
  <div className={`${styles.wrapper} ${darkMode ? styles.darkMode : ""}`}>
    <a className={styles.button} href='https://www.livehire.com/widgets/job-listings/tbwa/public?multiSegment=true' >
      {
        word.split("").map((c, i) => {
          return(<span key={`contactLetter_${i}`}>{c}</span>)
        })
      }
    </a>
  </div>
)}

export default ContactButton
